<template>
  <div class="stripe-container">
    <div class="payment-details-container">
      <div class="payment-details">
        <div class="total-charge-div">
          <div class="total-charge-text">TOTAL CHARGE:</div>
          <div class="total-charge-amount">${{ amountDue(amount) }}</div>
        </div>
        <div class="payment-break-down">
          <div class="text-content">
            <div class="text-head-title">Amount due:</div>
            <div class="text-head-title">${{ priceRoundUp(amount) }}</div>
          </div>
          <div class="text-content text-head-space">
            <div class="text-head-title">VAT:</div>
            <div class="text-head-title">${{ vatCharges(amount) }}</div>
          </div>
          <div class="text-content text-head-space">
            <div class="text-head-title">Transaction charges (3%):</div>
            <div class="text-head-title">${{ transactionFee(amount) }}</div>
          </div>
          <div class="text-content black-text text-head-space">
            <div class="text-head-title">Total:</div>
            <div class="text-head-title">${{ amountDue(amount) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="line-divider"></div>
    <div class="card-info-container">
      <div class="text-input-container">
        <div class="input_label label11">
          Card number<span
            id="card_number_error"
            class="error_message"
            style="float: right"
          ></span>
        </div>
        <!-- <div class="pass-field"><input type="text" v-model="form.card_number"  class="form-control"><img :src="'/img/credit-card/'+defaultcard+'.png'" height="48px"/></div> -->
        <div class="pass-field">
          <div id="card_number" class="card_number form-control"></div>
          <img
            :src="'/img/credit-card/' + defaultcard + '.png'"
            style="height: 24px; width: 24px"
          />
        </div>
        <div class="div_flex">
          <div class="valid_date" style="margin-right: 24px">
            <div class="input_label">Valid till</div>
            <div id="expiry_date" class="expiry_date form-control"></div>
          </div>
          <div class="valid_date">
            <div class="input_label">
              CVV
              <img
                style="float: right"
                src="/img/credit-card/question-mark.png"
              />
            </div>
            <div id="cvv" class="cvv form-control"></div>
          </div>
        </div>
        <div
          id="expiry_date_error"
          style="height: 22px; margin-top: 13px"
          class="error_message"
        ></div>
        <div>
          <div>
            <div class="input_label">Billing Address</div>
          
          <vue-google-autocomplete
              ref="autocomplete"
              id="map"
              classname="form-control addressTextBox"
              placeholder="Billing Address"
              v-on:placechanged="getAddressData"
              types=""
            >
            </vue-google-autocomplete>
            </div>
        </div>
        <div class="div_btn">
          <button
              @click="doValidation"
              :class="disabled == true ? 'grupa-disabled-btn' : null"
              :disabled="disabled"
              class="payment_btn grupa-blue-btn"
            >
              <img
                src="/img/onboarding/grey_lock.svg"
                style="margin-right: 16px"
              />
              <span>{{ btnText }} ${{ amountDue(amount)}}</span>
              <img
                src="/img/lightbox/preloader.gif"
                style="margin-left: 20px"
                class="button-spinner"
                v-show="spinner"
              />
            </button>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import mixPanel from "@/mixins/mixPanel";
import AlertSuccess from "@/components/alertSuccess";
import AlertError from "@/components/alertError";
import { stripePayment, savePaymentCard, debitCardAutoCharge } from "@/api";
export default {
  mixins: [mixPanel],
  props: {
    user: {
      type: Object,
      required: true,
    },
    paymentInfo: {
      type: Object,
      required: true,
    },
    userCreditCard: {
      type: Array,
      required: true,
    },
  },
  components: {
    VueGoogleAutocomplete,
    AlertSuccess,
    AlertError,
  },
  data: () => ({
    amount: 500,
    publishableKey: process.env.VUE_APP_STRIPE_PUB_KEY,
    form: {
      card_number: "",
    },
    defaultcard: "credit",
    cardType: {},
    stripe: Stripe(`${process.env.VUE_APP_STRIPE_PUB_KEY}`),
    cardNumber: "",
    cardExpiry: "",
    cardCvc: "",
    cardNumberError: "required",
    expiryDateError: "required",
    cvvError: "required",
    spinner: false,
    isSubmitted: false,
    disabled: false,
    btnText: "Pay",
    hasCreditCard: false,
    defaultCardId: null,
    cardIssuingCountry: null,
    modalType: "design_sprint",
    organization_address: "",
  }),

  created() {},
  mounted() {
    this.createStripeBox();
    // this.getUserLocation();
    if (this.userCreditCard.length > 0) {
      let defaultCard = this.userCreditCard.find(
        (card) => card.default_card == true
      );
      this.defaultCardId = defaultCard
        ? defaultCard.id
        : this.userCreditCard[0].id;
      this.cardIssuingCountry = defaultCard
        ? defaultCard.issuing_country
        : this.userCreditCard[0].issuing_country;
      this.hasCreditCard = true;
    } else {
      this.hasCreditCard = false;
    }
  },
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    ...mapActions("modalMgmt", ["updatePaymentModalFn"]),
    createStripeBox() {
      const stripe = this.stripe;
      const style = {
        base: {
          color: "#303238",
          fontSize: "16px",
          color: "#32325d",
          fontSmoothing: "antialiased",
          "::placeholder": {
            color: "#ccc",
          },
        },
        invalid: {
          color: "#e5424d",
          ":focus": {
            color: "#303238",
          },
        },
      };
      let elements = stripe.elements();
      // Create card details
      this.cardNumber = elements.create("cardNumber");
      this.cardExpiry = elements.create("cardExpiry", { style });
      this.cardCvc = elements.create("cardCvc", { style });

      // Mount the card to the dom
      this.cardNumber.mount("#card_number");
      this.cardExpiry.mount("#expiry_date");
      this.cardCvc.mount("#cvv");
      // Setup event listeners for change event
      this.cardNumber.addEventListener("change", (event) => {
        if (event.brand !== "unknown") {
          this.defaultcard = event.brand;
        } else {
          this.defaultcard = "credit";
        }
        var displayError = document.getElementById("card_number_error");
        if (event.error) {
          displayError.textContent = event.error.message;
          this.cardNumberError = event.error.message;
        } else {
          displayError.textContent = "";
          this.cardNumberError = "";
        }
        if (event.complete) {
          this.cardExpiry.focus();
        }
      });
      this.cardExpiry.addEventListener("change", (event) => {
        var displayError = document.getElementById("expiry_date_error");
        if (event.error) {
          displayError.textContent = event.error.message;
          this.expiryDateError = event.error.message;
        } else {
          displayError.textContent = "";

          this.expiryDateError = "";
        }
        if (event.complete) {
          this.cardCvc.focus();
        }
      });
      this.cardCvc.addEventListener("change", (event) => {
        var displayError = document.getElementById("expiry_date_error");
        if (event.error) {
          displayError.textContent = event.error.message;
          this.cvvError = event.error.message;
        } else {
          displayError.textContent = "";
          this.cvvError = "";
        }
      });
    },
    doValidation() {
      if (
        this.cardNumberError === "" &&
        this.expiryDateError === "" &&
        this.cvvError === ""
      ) {
        this.$developConsole(this.cardNumberError, this.expiryDateError);
        this.spinner = true;
        this.isSubmitted = true;
        this.submitForm();
      }
    },
    async submitForm() {
      this.disabled = true;
      this.btnText = "Processing";
      const cardDetails = {
        cardNumber: document.getElementById("card_number"),
        cardExpiry: document.getElementById("expiry_date"),
        cvv: document.getElementById("cvv"),
      };
      await this.stripe.createToken(this.cardNumber).then((result) => {
        if (result.error) {
          // Inform the customer that there was an error.
          var errorElement = document.getElementById("card_number_error");
          errorElement.textContent = result.error.message;
        } else if (this.modalType == "design_sprint") {
          let paymentPlan = "sprint";
          let cardUnique = this.isCardUnique(result.token.card);
          if (cardUnique) {
            const data = new FormData();
            const project_id = parseInt(this.$route.params.id, 10);
            data.append("project_id", project_id);
            data.append("token", result.token.id);
            data.append("email", this.user.email);
            data.append("purpose", this.paymentInfo.purpose);
            data.append("iteration_id", this.paymentInfo.iteration_id);
            data.append("payment_plan", this.paymentInfo.payment_plan);
            data.append("amount", this.amount);

            data.append("organization_address", this.organization_address);
            //this.organization_address
           // console.log(data)
            //console.log(this.organization_address)

           // alert('wait')
          // console.log(process.env.VUE_APP_STRIPE_PUB_KEY)
           this.handleStripePayment(data);
          } else {
            this.handleCardAutoCharge();
          }
        } else if (this.modalType == "add_card") {
          const dataFrom = new FormData();
          let cardUnique = this.isCardUnique(result.token.card);
          if (cardUnique) {
            dataFrom.append("token", result.token.id);
            dataFrom.append("email", this.user.email);
            savePaymentCard(dataFrom).then((response) => {
              this.$developConsole(response.data, "card added");
              if (response.data.status == 1) {
                this.spinner = false;
                this.isSubmitted = false;
                this.$emit("saveCard", response.data.stripe);
              }
            });
          } else {
            this.spinner = false;
            this.isSubmitted = false;
            this.setErrorMsg(
              "This card is already added to your account please try another card."
            );
          }
        }
      });
    },
   
    handleStripePayment(data) {
      stripePayment(data)
        .then((response) => {
          this.disabled = false;
          this.btnText = "Pay";
          this.spinner = false;
          if (response.data.status == 1) {
            this.$developConsole(response.data, "Payment Done !");

           
            this.updatePaymentModalFn(true);
            this.$emit("close");
            this.mixPanelTrack({
              event: "PaymentSuccessful",
            });
          } else {
            this.setErrorMsg("Transaction Failed");
            this.mixPanelTrack({
              event: "PaymentFailed",
            });
          }
        })
        .catch((error) => {
          this.disabled = false;
          this.btnText = "Pay";
          this.spinner = false;
          this.setErrorMsg("Transaction Failed");
          this.$developConsole(error.response, "Payment Error !");
          this.mixPanelTrack({
            event: "PaymentFailed",
          });
        });
    },
    priceRoundUp(data) {
      return data.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    transactionFee(amount) {
      let amountToPay = amount;
      let percent = amount * 0.075;
      let amountToPay1 = percent + amountToPay;
      return (0.03 * amountToPay1).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    vatCharges(amount) {
      let amountToPay = amount * 0.075;
      return amountToPay.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    amountDue(amount) {
      let amountDue = amount;
      let transactionFeePercent = amount * 0.075;
      let transactionFee = transactionFeePercent + amount;
      let totalTran = transactionFee * 0.03;
      let vatCharges = transactionFeePercent;
      let totalDue = amountDue + totalTran + vatCharges;
      return totalDue.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    isCardUnique(cardDetails) {
      let filterCard = this.userCreditCard.filter(
        (card) =>
          card.last4 == cardDetails.last4 &&
          card.card_brand == cardDetails.brand
      );
      if (filterCard.length == 0) {
        return true;
      } else {
        this.defaultCardId = filterCard[0].id;
        return false;
      }
    },
    handleCardAutoCharge() {
      const project_id = this.$route.params.id;
      this.spinner = true;
      this.isSubmitted = true;
      this.disabled = true;
      this.btnText = "Processing";
      let payload = {
        payment_plan: this.paymentInfo.payment_plan,
        iteration_id: this.paymentInfo.iteration_id,
        active_card_id: this.defaultCardId,
        purpose: this.paymentInfo.purpose,
        project_id,
      };
      debitCardAutoCharge(payload)
        .then((response) => {
          this.disabled = false;
          this.btnText = "Pay";
          this.spinner = false;
          if (response.data.status == 1) {
            this.updatePaymentModalFn(true);
            this.$emit("close");
            this.mixPanelTrack({
              event: "PaymentSuccessful",
            });
          } else {
            this.setErrorMsg("Please try again");
            this.mixPanelTrack({
              event: "PaymentFailed",
            });
          }
        })
        .catch((error) => {
          this.setErrorMsg("Please try again");
          this.disabled = false;
          this.btnText = "Pay";
          this.spinner = false;
          this.mixPanelTrack({
            event: "PaymentFailed",
          });
        });
    },
    getAddressData: function (addressData, placeResultData, id) {
      const { formatted_address } = placeResultData;
      let address;
      formatted_address
        ? (address = formatted_address)
        : (address = document.querySelector(".addressTextBox").value);
      this.organization_address = address;
    },
    getUserLocation() {
      let { city, country_name } = this.userLocation;
      let currentLocation = `${city}, ${country_name}`;
      document.querySelector(".addressTextBox").value = currentLocation;
      this.organization_address = currentLocation;
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser", "userLocation"]),
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.stripe-container {
  width: 50%;
  background: #ffffff;
  padding-left: 12px;
}
.payment-details-container {
  padding-left: 32px;
  /* margin-top: 116px; */
  margin-top: 92px;
}
.payment-details {
  width: 483px;
}

.total-charge-div {
  display: flex;
}
.total-charge-text {
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  margin-top: 14px;
  margin-right: 12px;
}
.total-charge-amount {
  font-weight: bold;
  font-size: 32px;
  line-height: 100%;
  letter-spacing: -1px;
  color: #1b1e22;
}
.payment-break-down {
  background: #f7f7f8;
  border-radius: 12px;
  margin-top: 24px;
  padding: 24px;
}
.text-content {
  display: flex;
  justify-content: space-between;
}
.text-head-title {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #979da5;
}
.text-head-space {
  margin-top: 16px;
}
.black-text div {
  color: #1b1e22;
}
.line-divider {
  margin-top: 24px;
  border-top: 1px solid #e4e5e7;
  /* width: 90%; */
  width: 628px;
}
.card-info-container {
  padding: 24px 0px 32px 32px;
}

/* Stripe card styles */
/* Need to revisit this and remove unused styles */
.text-input-container {
  width: 483px;
}
.input_label {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  text-align: left;
}
.div_flex {
  display: flex;
  margin-top: 24px;
}
.valid_date {
  width: 50%;
}
.div_btn {
  margin-top: 13px;
}
.payment_btn {
  width: 100%;
}

.stripe-card {
  background: #ffffff;
  border: 0.5px solid #979da5;
  box-sizing: border-box;
}

/*  */
.CardField-input-wrapper .CardField-cvc,
.CardField-input-wrapper .CardField-expiry {
  display: none !important;
}
.form-control {
  font-size: 14px;
  background: #ffffff;
  border: 0.5px solid #e4e5e7;
  box-sizing: border-box;
  margin-top: 8px;
  margin-bottom: 0px;
}
.pass-field img {
  right: 3px;
  top: 24%;
}
.pass-field {
  position: relative;
  height: 50px;
}
.pass-field img {
  position: absolute;
  top: 30%;
  right: 12px;
}
.error_message {
  font-weight: 100;
  font-size: 12px;
  color: red;
}
.card_number,
.expiry_date,
.cvv {
  padding-top: 16px;
}

.select_credit_card {
  padding: 48px 24px;
}
.section_header {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  align-items: center;
  color: #15171a;
  text-align: left;
}
.credit_card_div {
  display: flex;
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 18px 16px;
  text-align: initial;
}
.div_card_digit {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-left: 16px;
  padding-top: 5px;
}
.left_info {
  width: 40%;
  display: flex;
}
.right_info {
  width: 60%;
  justify-content: flex-end;
  display: flex;
}
.use_card_div {
  margin-top: 24px;
  font-family: Moderat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #0055a5;
  text-align: left;
  cursor: pointer;
}
@media (max-width: 768px) {
  .payment-details,
  .stripe-container {
    width: 100%;
  }
  .payment-details-container {
    padding-right: 32px;
    margin-top: 24px;
  }

  .card-info-container {
    padding-right: 32px;
  }
  .text-input-container {
    width: 100%;
  }
  .line-divider {
    width: 90%;
  }
}
</style>
