var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "navbar-container" }, [_c("Navbar")], 1),
      _vm.projectDetails == null
        ? _c("div", { staticClass: "loader-wrapper" }, [_c("Loader")], 1)
        : _vm._e(),
      _vm.projectDetails
        ? _c(
            "section",
            { staticClass: "section-wrapper" },
            [
              _c("PaymentInformation", {
                attrs: { previousRoute: this.previousRoute },
              }),
              _c(
                "StripePayment",
                _vm._b(
                  {},
                  "StripePayment",
                  {
                    user: _vm.user,
                    modalType: _vm.modalType,
                    paymentInfo: _vm.paymentInfo,
                    userCreditCard: _vm.userCreditCard,
                  },
                  false
                )
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.paymentModalVisible
        ? _c("PaymentSuccess", { on: { handleNextPage: _vm.handleNextPage } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }