var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "payment-description" }, [
    _c("div", [
      _c("div", { staticClass: "top-container" }, [
        _c(
          "div",
          { staticClass: "go-back-btn", on: { click: _vm.handleGoBack } },
          [
            _c("img", {
              attrs: { src: "/img/onboarding/go-back-icon.svg", alt: "" },
            }),
            _c("span", [_vm._v("Go Back")]),
          ]
        ),
        _c("div", { staticClass: "page-title" }, [
          _vm._v("Grupa Startup Priority Listing"),
        ]),
        _vm._m(0),
        _c(
          "div",
          { staticClass: "priority-benefit" },
          _vm._l(_vm.priorityBest, function (item, index) {
            return _c("div", { key: index, staticClass: "list-item" }, [
              _vm._m(1, true),
              _c("div", { staticClass: "list-text" }, [
                _vm._v("\n            " + _vm._s(item) + "\n          "),
              ]),
            ])
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "line-divider" }),
      _c(
        "div",
        { staticClass: "faq-wrapper" },
        [
          _c(
            "v-expansion-panel",
            {
              model: {
                value: _vm.currSection,
                callback: function ($$v) {
                  _vm.currSection = $$v
                },
                expression: "currSection",
              },
            },
            [
              _c(
                "v-expansion-panel-content",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "actions",
                      fn: function () {
                        return [
                          _vm.currSection
                            ? _c("img", {
                                attrs: {
                                  src: "/img/onboarding/chevron-up.svg",
                                },
                              })
                            : _c("img", {
                                attrs: {
                                  src: "/img/onboarding/chevron-down.svg",
                                },
                              }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "box-text" }, [
                            _vm._v("FAQs"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "div",
                    { staticClass: "faq-container" },
                    [
                      _c(
                        "VuePerfectScrollbar",
                        {
                          staticClass: "perfect-scroll-area",
                          attrs: { settings: _vm.settings },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "scroll-height" },
                            _vm._l(_vm.faqList, function (faq, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "single-faq" },
                                [
                                  _c("div", { staticClass: "faq-question" }, [
                                    _vm._v(_vm._s(faq.question)),
                                  ]),
                                  _c("div", { staticClass: "faq-answer" }, [
                                    _vm._v(_vm._s(faq.answer)),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "skip-container" }, [
      _c("div", { staticClass: "card-cion" }, [
        _c("img", { attrs: { src: "/img/onboarding/skip-icon.svg", alt: "" } }),
      ]),
      _c("div", { staticClass: "card-text-content" }, [
        _c("div", { staticClass: "bold-text" }, [
          _vm._v("You’re skipping the Queue!"),
        ]),
        _c("div", { staticClass: "wait-list" }, [
          _vm._v(
            "\n            56+ startups currently on the Grupa waitlist\n          "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list-img" }, [
      _c("img", {
        attrs: { src: "/img/onboarding/green-list-icon.svg", alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }