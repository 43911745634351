<template>
  <div>
    <div class="navbar-container">
      <Navbar />
    </div>
    <div class="loader-wrapper" v-if="projectDetails == null">
      <Loader />
    </div>
    <section v-if="projectDetails" class="section-wrapper">
      <PaymentInformation :previousRoute="this.previousRoute"/>
      <StripePayment
        v-bind="{ user, modalType, paymentInfo, userCreditCard }"
      />
    </section>
    <PaymentSuccess
      v-if="paymentModalVisible"
      v-on:handleNextPage="handleNextPage"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import mixPanel from "@/mixins/mixPanel";
import { completeProjectDetails, clientPaymentCards } from "@/api";
import Navbar from "@/components/Navbar/navbar";
import Loader from "@/components/general/loader";
import PaymentInformation from "./PaymentInformation.vue";
import StripePayment from "./StripePayment.vue";
import PaymentSuccess from "@/components/paymentSuccess";

export default {
  mixins: [mixPanel],
  components: {
    Navbar,
    Loader,
    PaymentInformation,
    StripePayment,
    PaymentSuccess,
  },
  data: () => ({
    openStripe: false,
    modalType: "design_sprint",
    paymentInfo: {
      purpose: "team preview",
      payment_plan: "none",
      iteration_id: null,
    },
    publishableKey: process.env.VUE_APP_STRIPE_PUB_KEY,
    projectDetails: null,
    ip: "",
    userCreditCard: [],
    openCardList: false,
    paySpinner: false,
    defaultCardId: null,
    projectTeam: {},
    ndaModal: false,
    ndaModal: false,
    userVoucher: "",
    teamEvaluationFee: 500,
    showCalendly: false,
    loadCalendly: true,
    previousRoute: "project_workspace",
  }),
  created() {
    const id = this.$route.params.id;
    this.getClientProject(id);
    this.clientCreditCard();
  },
  beforeRouteEnter(_to, from, next) {
    next((vm) => {
      vm.previousRoute = from.name || "project_workspace";
    });
  },
  mounted() {
    this.updatePaymentModalFn(false);
    this.mixPanelTrack({
      event: "GotToPaymentPage",
    });
  },
  methods: {
    ...mapActions("clientDashboard", ["updateProject"]),
    ...mapActions("modalMgmt", ["updatePaymentModalFn"]),
    getClientProject(id) {
      completeProjectDetails(id)
        .then((res) => {
          this.projectDetails = res.data.project;
          this.updateProject(res.data.project);
          this.paymentInfo.iteration_id = res.data.documents.iterations[0].id;
        })
        .catch((err) => {});
    },
    clientCreditCard() {
      clientPaymentCards()
        .then((response) => {
          this.userCreditCard = response.data.cards;
          this.$developConsole(response.data.cards, "Api Response");
        })
        .catch((error) => {
          this.$developConsole(error.response, "Error Response");
        });
    },
    handleNextPage() {
      const id = this.$route.params.id;
      this.$router.push({
        name: "schedule_call",
        params: { id },
      });
    },
  },

  computed: {
    ...mapState("userManagement", ["currentUser"]),
    ...mapState("modalMgmt", ["paymentModalVisible"]),

    user() {
      return this.currentUser;
    },
  },
};
</script>

<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.navbar-container {
  position: fixed;
  width: 100vw;
  z-index: 100;
}
.loader-wrapper {
  margin-top: 80px;
}
.section-wrapper {
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .section-wrapper {
    flex-wrap: wrap;
  }
}
</style>
