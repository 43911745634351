<template>
  <div class="payment-description">
    <div>
      <div class="top-container">
        <div class="go-back-btn" @click="handleGoBack">
          <img src="/img/onboarding/go-back-icon.svg" alt="" />
          <span>Go Back</span>
        </div>
        <div class="page-title">Grupa Startup Priority Listing</div>
        <div class="skip-container">
          <div class="card-cion">
            <img src="/img/onboarding/skip-icon.svg" alt="" />
          </div>
          <div class="card-text-content">
            <div class="bold-text">You’re skipping the Queue!</div>
            <div class="wait-list">
              56+ startups currently on the Grupa waitlist
            </div>
          </div>
        </div>
        <div class="priority-benefit">
          <div
            v-for="(item, index) in priorityBest"
            :key="index"
            class="list-item"
          >
            <div class="list-img">
              <img src="/img/onboarding/green-list-icon.svg" alt="" />
            </div>
            <div class="list-text">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div class="line-divider"></div>
      <!-- Start -->
      <div class="faq-wrapper">
        <v-expansion-panel v-model="currSection">
          <v-expansion-panel-content>
            <template v-slot:actions>
              <img v-if="currSection" src="/img/onboarding/chevron-up.svg" />
              <img v-else src="/img/onboarding/chevron-down.svg" />
            </template>
            <template v-slot:header>
              <div class="box-text">FAQs</div>
            </template>
            <div class="faq-container">
              <VuePerfectScrollbar
                class="perfect-scroll-area"
                :settings="settings"
              >
                <div class="scroll-height">
                  <div
                    v-for="(faq, index) in faqList"
                    :key="index"
                    class="single-faq"
                  >
                    <div class="faq-question">{{ faq.question }}</div>
                    <div class="faq-answer">{{ faq.answer }}</div>
                  </div>
                </div>
              </VuePerfectScrollbar>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </div>
    </div>
  </div>
</template>

<script>
import mixPanel from "@/mixins/mixPanel";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
export default {
  props: {
    previousRoute: String,
  },
  mixins: [mixPanel],
  components: {
    VuePerfectScrollbar,
  },
  data: () => ({
    currSection: null,
    priorityBest: [
      "Meet experts immediately",
      "Get priority support",
      "You’ll be refunded if not satisfied",
    ],
    faqList: [
      {
        question: `What is the Priority List?`,
        answer: `If you are ready to start building your product ASAP and want the best-fit teams to be reserved, the priority list is for you`,
      },
      {
        question: `What is the Priority payment?`,
        answer: `Priority payment is a deposit of USD $500 to have your request prioritized and get the best-fit teams reserved for you.`,
      },
      {
        question: `Do I need to get qualified to be listed on the priority list?`,
        answer: `We prioritize companies that are venture-backed. Once the priority payment is processed, our team activates the verification step to ensure your company is funded. In a case where this qualification is not met, we immediately refund the priority payment while adding you to a waitlist. If you have any questions about the waitlist, please check our FAQ page.`,
      },
      {
        question: `Why should I pay before I meet the teams?`,
        answer: `This payment prioritizes your request and enables our matchmaking platform to expedite your team selection process. Your payment allows you to be the first in line reserving the best-fit teams.`,
      },
      {
        question: `What if I’m not satisfied after meeting a team?`,
        answer: `Our goal is to match you with the best-fit teams. The process starts with understanding your requirements and ends with meeting those requirements. After each meeting, we take your feedback and modify our matchmaking process accordingly.`,
      },
      {
        question: `What happens after I pay the Priority Payment?`,
        answer: `Once you deposit the priority payment, our platform immediately prioritizes your startup, and notifies our matchmakers to provide a white-glove experience & match you with the best-fit expert teams ahead of others. You can then reserve the team that best fits your needs, regardless of when you start the project.`,
      },
      {
        question: `Who are Grupa experts?`,
        answer: `Experts, listed on the Grupa marketplace, are top technical talents and FAANG employees who will work as contractors and tech advisors while leveraging their free time, expertise, and experience to build quality products. Grupa believes in excellence! `,
      },

      {
        question: `How do you screen and qualify experts on Grupa?`,
        answer: `To be a verified expert on Grupa, you must be a top talent from FAANG with great past experience, communication skills, work attitude, and top-level professionalism. `,
      },
      {
        question: `How do I get matched with the best-fit expert team?`,
        answer: `We ensure you communicate your product idea, requirements, and industry expertise on the platform. That way, we help you get matched with the best-fit expert team to build your product. `,
      },
      {
        question: `What’s the structure of the team?`,
        answer: `That is totally up to you and your current product requirements. With that being said, Grupa teams generally consist of 4 experts across design, engineering, product, and data.`,
      },
      {
        question: `Are there other fees involved?`,
        answer: `Yes! Before you get matched with a team, you need to pick the right compensation model that fits your startup objective. The compensation model between startups and expert teams is effective in three possible ways; Cash only  (starts at $10k), Cash & Equity (starts at $5k + 0.125%), and Equity only (starts at 0.375%).`,
      },
      {
        question: `How do I better understand the payment cycle?  How often will I be charged? `,
        answer: `You pay Grupa in advance every month per iteration. This way, the expert team gets to be paid either bi-weekly or monthly from Grupa depending on the iteration plan between you and the expert team. This payment cycle happens automatically until the completion of your product.`,
      },
      {
        question: `How does Grupa charge startups?`,
        answer: `Grupa operates in a recurring subscription model depending on your preferred iteration plan with the expert team.`,
      },
      {
        question: `What methods of payment do you accept?`,
        answer: `We accept all major credit cards and Stripe.`,
      },
    ],
    settings: {
      maxScrollbarLength: 60,
    },
  }),
  created() {},
  mounted() {},
  methods: {
    handleGoBack() {
      this.$router.push({
        name: this.previousRoute,
      });
      this.mixPanelTrack({
        event: `BackFromReserveTeamTo${this.previousRoute}`,
      });
    },
  },
  computed: {},
};
</script>

<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.payment-description {
  width: 50%;
  background: #f7f7f8;
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  /* align-items: center; */
  min-height: calc(100vh - 80px);
}
.top-container {
  width: 483px;
  margin-top: 24px;
  /* margin-top: 48px; */
  text-align: left;
}
.go-back-btn {
  cursor: pointer;
}
.go-back-btn span {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #0781f2;
}
.page-title {
  font-weight: bold;
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
  margin-top: 48px;
}
.skip-container {
  background-color: rgb(84, 207, 148, 0.1);
  border-radius: 12px;
  display: flex;
  margin-top: 24px;
  padding: 24px;
  gap: 22px;
  width: 407px;
}
.card-text-content {
}
.bold-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #1fa564;
}
.wait-list {
  font-size: 14px;
  line-height: 17px;
  color: #53585f;
  margin-top: 8px;
}
.list-item {
  display: flex;
  margin-top: 24px;
}
.list-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #1b1e22;
  margin-left: 8px;
}
.line-divider {
  margin-top: 24px;
  border-top: 1px solid #e4e5e7;
  width: 90%;
}
.box-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #1b1e22;
}
.box-text img {
  cursor: pointer;
}
.faq-container {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  box-shadow: 2px 2px 20px rgba(21, 23, 26, 0.05);
  border-radius: 8px;
  border-top: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  width: 483px;
  margin-left: -1px;
  padding-bottom: 32px;
}
.scroll-height {
  height: 423px;
  padding: 0px 16px 0px 16px;
}
.single-faq {
  width: 451px;
  text-align: left;
  margin-bottom: 32px;
}
.faq-question {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #1b1e22;
}
.faq-answer {
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #53585f;
}

@media (max-width: 768px) {
  .payment-description {
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
    flex-direction: column;
    justify-content: initial;
    min-height: auto;
  }
  .faq-container,
  .single-faq {
    width: 100%;
  }
  .page-title {
    margin-top: 24px;
  }
  .top-container {
    width: 100%;
    margin-top: 24px;
  }
}

@media (max-width: 450px) {
  .skip-container {
    width: 100%;
  }
}
</style>


<style>
.faq-wrapper .v-expansion-panel__container {
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  box-shadow: 2px 2px 20px rgba(21, 23, 26, 0.05);
  border-radius: 8px;
  width: 483px;
}
.faq-wrapper .v-expansion-panel {
  -webkit-box-shadow: none;
}
.faq-wrapper .v-expansion-panel__container:first-child {
  border-top: 1px solid #e4e5e7 !important;
}
.faq-wrapper .v-expansion-panel__header {
  padding: 16px;
}
.faq-wrapper .v-expansion-panel__container--active .v-expansion-panel__header {
  background: #ffffff;
}
</style>
